$offset: 1;

body,
html {
	background: black;
	overscroll-behavior: none;
}
picture {
	background: none;
	border: none;
	img {
		width: 100%;
		background: transparent;
	}
}
@mixin styling($offset) {
	.App {
		position: absolute;
		transition: filter 0.8s ease-in-out;
		width: calc(9600px * #{$offset});
		height: calc(5400px * #{$offset});
		background: radial-gradient(#e66465, #9198e5);
		background: black;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0) translateZ(0px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
		transform-style: inherit;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.LoadingScreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: black;
		transition: opacity 1s ease-in-out;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			width: calc(546.128px * #{$offset});
			margin-top: calc(-90px * #{$offset});
			// transform: translate3d(-50%, -50%, 0);
		}
		.PreventionText {
			position: absolute;
			bottom: 30%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			color: white;
			font-size: calc(22.2px * #{$offset});
			color: white;
			font-weight: 400;
			font-family: "Times New Roman", sans-serif;
			text-align: center;
		}
		.LoadingGlobal {
			position: absolute;
			bottom: 20%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			text-transform: uppercase;
			font-weight: bold;
			font-size: calc(30.2px * #{$offset});
			animation: Click 0.8s ease-in-out infinite;
			color: white;
		}
	}
	.DrawingButton {
		position: absolute;
		top: 14%;
		left: 63.9%;
		color: white;
		background: black;
		font-size: calc(19.2px);
		padding: 5px 20px;
		z-index: 50;
		cursor: pointer;
		outline: none;
		font-family: "Times New Roman", Times, serif;
		border: solid 1px white;

		&.Clear {
			top: 9%;
			left: 69%;
		}
		&.Save {
			text-decoration: none;
			top: 10%;
			left: 69%;
		}
	}
	.CanvasWrapper {
		width: calc(700px * #{$offset});
		height: calc(700px * #{$offset});
		position: absolute;
		top: 1%;
		left: 61%;
		background: white;
	}
	.DrawingCanvas {
		background: transparent;
		position: absolute;
		top: 0;
		border: solid 5px black;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.TShirtVierge {
		background: white;
		position: absolute;
		width: 100%;
		height: 100%;
		transform: scale(1.2);
		top: 0%;
		left: 0%;
	}

	.ArrowTshirtOne {
		position: absolute;
		top: 15%;
		left: 65.4%;
		z-index: 310;
		width: calc(150px * #{$offset});
		transform: rotate(200deg);
	}
	.ClickHereTshirt {
		position: absolute;
		top: 16%;
		left: 67%;
		width: calc(120px * #{$offset});
		animation: Click 0.4s ease-in-out infinite;
	}

	h1 {
		font-size: calc(22.2px * #{$offset});
		color: white;
		font-weight: 400;
		letter-spacing: 3px;
		margin-top: calc(-60px * #{$offset});
	}

	p {
		color: white;
		text-align: center;
		font-size: calc(15.8px * #{$offset});
	}
	.MainTitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		svg {
			width: calc(546.128px * #{$offset});
		}
	}

	/*----- MAIN ITEM -----*/
	.Flower {
		width: calc(1394px * #{$offset});
		z-index: 2;
		position: absolute;
		left: 38%;
		top: 55%;
		transform: translate(-50%, -50%) scale(0.85);
	}
	.ShopNow {
		position: absolute;
		z-index: 300;
		text-decoration: none;
		color: white;
		background: black;
		font-size: calc(19.2px * #{$offset});
		padding: 5px 20px;
		cursor: pointer;
		outline: none;
		text-decoration: none;
		font-family: "Times New Roman", Times, serif;
		border: solid 1px white;

		&.One {
			top: 56%;
			left: 32.8%;
		}
		&.Two {
			top: 41.5%;
			left: 36%;
		}
		&.Three {
			top: 56%;
			left: 62.2%;
		}
		&.Four {
			right: 3.7%;
			top: 74.5%;
		}
	}
	.AndRollLetter {
		position: absolute;
		top: 20%;
		left: 22%;
		width: calc(786px * #{$offset});
	}
	.Invitation {
		width: calc(500px * #{$offset});
		position: absolute;
		left: 33%;
		top: 69%;
		z-index: 300;
	}

	.AndrollExplanation {
		position: absolute;
		width: calc(600px * #{$offset});
		left: 48%;
		top: 70%;
		z-index: 300;
	}

	.SubscribeButton {
		position: absolute;
		left: 34.5%;
		top: 81.8%;
		z-index: 300;
		text-decoration: none;
		color: white;
		background: black;
		font-size: calc(19.2px * #{$offset});
		padding: 5px 20px;
		cursor: pointer;
		outline: none;
		font-family: "Times New Roman", Times, serif;
		border: solid 1px white;
	}

	.Different {
		width: calc(1684px * #{$offset});
		position: absolute;
		left: 43%;
		top: 32%;
		transform: translate(-50%, -50%) scale(0.85);
	}

	.Two_Teesh {
		width: calc(1948px * #{$offset});
		z-index: 2;
		position: absolute;
		left: 65%;
		top: 45%;
		transform: translate(-50%, -50%) scale(0.85);
	}
	.GuitareJack {
		width: calc(5201px * #{$offset});
		z-index: 3;
		position: absolute;
		top: 39%;
		left: 0.12%;
	}
	.Veste {
		position: absolute;
		width: calc(1546px * #{$offset});
		right: 0;
		top: 33%;
	}
	.Chaine {
		z-index: 3;
		width: calc(2747px * #{$offset});
		position: absolute;
		left: 65%;
		top: 37%;
		transform: translate(-50%, -50%);
	}

	/*----- VIDEO PART -----*/
	.HorseMask {
		position: absolute;
		top: 0;
		left: 36%;
		transform: translateX(-50%);
		width: calc(895px * #{$offset});
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
	}
	.Loop_Aff {
		width: calc(620px * #{$offset});
		min-height: calc(800px * #{$offset});
		position: absolute;
		transition: opacity 0.8s ease-in-out;
		opacity: 0;
		top: -3%;
		left: 49%;
	}
	.Mood_Aff {
		width: calc(412px * #{$offset});
		position: absolute;
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		top: 20%;
		left: 51%;
	}
	.Plaque {
		width: calc(777px * #{$offset});
		min-height: calc(251px * #{$offset});
		position: absolute;
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		left: 37%;
		top: 14%;
		transform: translate(-50%, -50%);
	}
	.Panno {
		width: calc(400px * #{$offset});
		position: absolute;
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		left: 52%;
		top: 17%;
		transform: translate(-50%, -50%);
	}
	.StoryBoard {
		width: calc(650px * #{$offset});
		position: absolute;
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		left: 32%;
		top: 17%;
	}

	.Loophole {
		width: calc(640px * #{$offset});
		position: absolute;
		height: calc(360px * #{$offset});
		top: 1%;
		left: 42%;
		opacity: 1;
		transition: 0.8s ease-in-out;
		transform: rotate(3deg);
	}
	.TheParty {
		width: calc(640px * #{$offset});
		height: calc(360px * #{$offset});
		position: absolute;
		top: 13%;
		left: 42%;
		transform: rotate(358deg);
	}
	.TheMood {
		width: calc(640px * #{$offset});
		z-index: 5;
		position: absolute;
		top: 28%;
		left: 59%;
		transform: translate(-50%, -50%);
	}

	/*----- MUSIC PART -----*/
	.Boomer {
		width: calc(730px * #{$offset});
		position: absolute;
		left: 0;
		top: 11%;
		z-index: 10;
	}
	.Piano {
		width: calc(1988px * #{$offset});
		position: absolute;
		top: 0;
		left: 0;
	}
	.CD {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(990px * #{$offset});
		position: absolute;
		top: 10%;
		left: 20%;
		.CDExplanation {
			position: absolute;
			top: 98%;
			left: 31%;
			font-size: calc(16px * #{$offset});
			font-weight: bold;
			opacity: 0;
			transition: opacity 0.4s ease-in-out;
		}
		.CDLoading {
			position: absolute;
			top: 30%;
			right: 12%;
			z-index: 100;
			text-transform: uppercase;
			font-size: calc(45px * #{$offset});
			font-weight: bold;
			opacity: 0;
			color: white;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);

			&.active {
				opacity: 1;
				animation: clignoting 0.4s infinite ease-in-out;
			}
		}
		.Al-C {
			display: block;
			width: 100%;
		}
		.Al-Op {
			display: none;
		}
		.Al-Cd {
			display: none;
		}
		.ClickableArea {
			display: none;
		}
		&.open {
			.CDExplanation {
				opacity: 1;
			}
			.Al-C {
				display: none;
			}
			.Al-Op {
				display: block;
				width: 100%;
			}
			.Al-Cd {
				position: absolute;
				width: calc(420px * #{$offset});
				right: calc(45px * #{$offset});
				top: calc(28px * #{$offset});
				display: block;
				&.rotate {
					animation: CdRotate 2s linear infinite;
					cursor: pointer;
				}
			}
			.ClickableArea {
				display: block;
				position: absolute;
				left: 12%;
				top: 23%;
				transform: rotate(-2deg);
				width: 22%;
				height: 56%;
				cursor: pointer;
				div {
					width: 100%;
					height: 7%;
					&:nth-child(n + 5) {
						height: 6.5%;
					}
				}
			}
		}
	}
	.ArrowCD {
		position: absolute;
		top: 8%;
		left: 30%;
		transform: rotate(139deg);
		width: calc(203px * #{$offset});
	}
	.ClickHereCD {
		position: absolute;
		top: 6%;
		left: 31.8%;
		width: calc(120px * #{$offset});
		animation: Click 1.2s ease-in-out infinite;
	}
	.CLT {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(847px * #{$offset});
		position: absolute;
		top: 30%;
		left: 10%;
		z-index: 20;
	}
	.Tired {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(500px * #{$offset});
		position: absolute;
		top: 27%;
		left: 22%;
		z-index: 20;
	}
	.Ampoule {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(800px * #{$offset});
		position: absolute;
		bottom: 17%;
		left: 11%;
	}
	.BurningFlower {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(300px * #{$offset});
		position: absolute;
		top: 42%;
		left: 20%;
	}
	.ChildBeat {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(480px * #{$offset});
		position: absolute;
		top: 38%;
		left: 25%;
		z-index: 50;
		animation: clignoting 0.1s ease-in-out infinite;
	}
	.Mediator {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(150px * #{$offset});
		position: absolute;
		top: 36%;
		left: 22%;
		z-index: 50;
		transform: rotate(-10deg);
	}
	.Lightning {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(480px * #{$offset});
		position: absolute;
		top: 16%;
		left: 34%;
	}
	.Free {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(500px * #{$offset});
		position: absolute;
		top: 50%;
		left: 25%;
		z-index: 50;
	}
	.SkullExplosing {
		opacity: 1;
		width: calc(1200px * #{$offset});
		position: absolute;
		bottom: 19%;
		left: 20%;
		z-index: 100;
		filter: saturate(0);
	}

	.Vinyl2 {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		width: calc(2079px * #{$offset});
		position: absolute;
		bottom: 0;
		left: 32%;
	}
	.Vinyl3 {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		z-index: 5;
		width: calc(1262px * #{$offset});
		position: absolute;
		bottom: 0;
		left: 300px;
	}
	.Disques {
		width: calc(1649px * #{$offset});
		min-height: calc(500px * #{$offset});
		position: absolute;
		bottom: 0;
		transition: opacity 0.8s ease-in-out;
		opacity: 0;
		left: 15%;
	}

	/* --- Electribe --- */
	.ElectribeWrapper {
		width: calc(912px * #{$offset});
		position: absolute;
		top: 54%;
		left: 10%;
		z-index: 50;
		transform: rotate(-5deg);
	}

	.KeyboardExplanation {
		position: absolute;
		top: 66.5%;
		left: 10.5%;
		width: calc(600px * #{$offset});
		transform: rotate(-2deg);
		z-index: 100;
		opacity: 0;
		transition: 0.4s ease-in-out;
	}

	.Electribe {
		width: 100%;
	}
	.KeyWrapper {
		width: calc(711.375px * #{$offset});
		position: absolute;
		bottom: 22%;
		left: 22%;
	}

	.Key {
		opacity: 0;
		transition: opacity 0.1s ease-in-out;
	}

	.Key1 {
		width: calc(82px * #{$offset});
	}
	.Key2 {
		width: calc(80px * #{$offset});
		margin-left: -6%;
	}

	.Key3 {
		width: calc(82px * #{$offset});
		margin-left: -6%;
	}

	.Key4 {
		width: calc(105px * #{$offset});
		margin-left: -7.5%;
	}

	.Key5 {
		width: calc(78px * #{$offset});
		margin-left: -7.5%;
	}
	.Key6 {
		width: calc(76px * #{$offset});
		margin-left: -5.2%;
	}

	.Key7 {
		width: calc(81px * #{$offset});
		margin-left: -5.5%;
	}

	.Key8 {
		width: calc(78px * #{$offset});
		margin-left: -5.8%;
	}
	.Key9 {
		width: calc(72px * #{$offset});
		margin-left: -5%;
	}

	.PlayButton {
		width: calc(124px * #{$offset});
		position: absolute;
		top: 53.5%;
		left: 21.5%;
	}
	.PlayButton picture {
		width: 100%;
	}

	.Screen {
		width: calc(132px * #{$offset});
		position: absolute;
		top: 27.5%;
		left: 10%;
		animation: clignoting 0.6s ease-in-out infinite;
	}
	.Screen img {
		width: 100%;
	}

	.SouncloudPlayer {
		width: calc(400px * #{$offset});
		height: calc(600px * #{$offset});
		position: absolute;
		left: 11%;
		top: 14%;
	}
	.SouncloudPlayer2 {
		width: calc(400px * #{$offset});
		height: calc(600px * #{$offset});
		position: absolute;
		left: 16%;
		top: 14%;
	}
	.ArrowElectribeOne {
		position: absolute;
		top: 61%;
		z-index: 300;
		left: 9.7%;
		width: calc(250px * #{$offset});
	}
	.ClickHereElectribe {
		position: absolute;
		top: 60%;
		left: 8%;
		width: calc(160px * #{$offset});
		animation: Click 0.3s ease-in-out infinite;
	}

	.SouncloudPlayerRagetape {
		width: calc(882px * #{$offset});
		height: calc(200px * #{$offset});
		position: absolute;
		left: 11%;
		top: 26%;
	}

	.Bottle {
		position: absolute;
		top: 25%;
		left: 30.5%;
		z-index: 300;
		cursor: pointer;
		width: calc(250px * #{$offset});
	}

	.ArrowBottleOne {
		position: absolute;
		top: 30%;
		left: 33.5%;
		z-index: 310;
		width: calc(250px * #{$offset});
		transform: rotate(160deg);
	}

	.ClickHereBottle {
		position: absolute;
		top: 28%;
		left: 36%;
		width: calc(160px * #{$offset});
		animation: Click 1s ease-in-out infinite;
	}

	/* --- Graphism Part --- */
	.Draws_one {
		position: absolute;
		width: calc(1824px * #{$offset});
		min-height: calc(1600px * #{$offset});
		right: 0;
		opacity: 0;
		transition: 0.8s ease-in-out;
		top: 0%;
	}
	.Different_Aff {
		position: absolute;
		width: calc(580px * #{$offset});
		top: 25%;
		left: 83%;
		opacity: 0;
		transition: 0.8s ease-in-out;
		z-index: 14;
		transform: translateZ(10px) rotate(-10deg);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
		transform-style: inherit;
	}

	.Flouk_Badge {
		width: calc(47px * #{$offset});
		position: absolute;
		top: 92%;
		z-index: 50;
		left: 31%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}

	.Affiche_1 {
		width: calc(612px * #{$offset});
		position: absolute;
		top: 3%;
		left: 77%;
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: translateZ(15px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
		transform-style: inherit;
	}
	.Affiche_2 {
		width: calc(276px * #{$offset});
		position: absolute;
		top: 8%;
		left: 74%;
		transform: rotate(-10deg);
		z-index: -1;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Affiche_3 {
		width: calc(300px * #{$offset});
		position: absolute;
		top: 10.5%;
		left: 80.5%;
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: translateZ(10px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
	}
	.FreedomOnly {
		position: absolute;
		top: 19.5%;
		z-index: 1;
		left: 81.2%;
		width: calc(260px * #{$offset});
		opacity: 0;
		transition: 0.8s ease-in-out;
	}

	.MultiHead {
		position: absolute;
		top: 2.8%;
		left: 82.5%;
		z-index: 2;
		opacity: 0;
		transform: translateZ(30px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
		transform-style: inherit;
		transition: 0.8s ease-in-out;
		width: calc(500px * #{$offset});
	}
	.LovinSkull {
		width: calc(201px * #{$offset});
		position: absolute;
		top: 13%;
		transform: translateZ(40px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
		transform-style: inherit;
		left: 84.5%;
		z-index: 15;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.TwoGirls {
		width: calc(605px * #{$offset});
		position: absolute;
		top: 14%;
		left: 88%;
		z-index: 1;
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: translateZ(15px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
	}
	.MusicSkull {
		width: calc(75px * #{$offset});
		position: absolute;
		top: 12%;
		left: 87.7%;
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: rotate(-40deg) translateZ(50px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
	}
	.Cat {
		width: calc(300px * #{$offset});
		position: absolute;
		top: 4%;
		left: 88.1%;
		z-index: 1;
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: translateZ(25px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
	}
	.LoveHate {
		width: calc(600px * #{$offset});
		position: absolute;
		transform: rotate(5deg);
		top: 5.6%;
		left: 93.5%;
		z-index: 1;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.PhoneNumber {
		width: calc(410px * #{$offset});
		position: absolute;
		top: 11.6%;
		left: 89%;
		z-index: 1;
		transform: rotate(-1deg);
		opacity: 0;
		transition: 0.8s ease-in-out;
		transform: translateZ(35px);
		transform-origin: 0;
		-webkit-transform-style: inherit;
		-moz-transform-style: inherit;
		-ms-transform-style: inherit;
	}
	.AvionEnPapier {
		width: calc(100px * #{$offset});
		position: absolute;
		top: 20%;
		left: 65%;
		z-index: 100;
		transform: rotate(-1deg);
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
		transform: translateZ(50px);
		animation: Flying 5s ease-in-out infinite;
	}
	/* --- Photo Part --- */
	.Polaroid {
		width: calc(2481px * #{$offset});
		position: absolute;
		top: 60%;
		left: 60%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Frame_1 {
		width: calc(455px * #{$offset});
		position: absolute;
		top: 74%;
		left: 60.5%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Frame_2 {
		width: calc(455px * #{$offset});
		position: absolute;
		top: 74%;
		left: 66.5%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Frame_3 {
		width: calc(455px * #{$offset});
		position: absolute;
		top: 74%;
		left: 72.6%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Cadre_1 {
		width: calc(407px * #{$offset});
		position: absolute;
		top: 82%;
		left: 60%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Cadre_2 {
		width: calc(407px * #{$offset});
		position: absolute;
		top: 82%;
		left: 65%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Cadre_3 {
		width: calc(407px * #{$offset});
		position: absolute;
		top: 82%;
		left: 70%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}
	.Cadre_4 {
		width: calc(561px * #{$offset});
		position: absolute;
		top: 82%;
		left: 75%;
		opacity: 0;
		transition: 0.8s ease-in-out;
	}

	.Treize {
		width: calc(300px * #{$offset});
		position: absolute;
		top: 93%;
		left: 95.8%;
		opacity: 0;
		z-index: 100;
		transition: 0.8s ease-in-out;
		transform: rotate(-5deg);
	}

	.PolaGif {
		width: calc(700px * #{$offset});
		position: absolute;
		cursor: pointer;
		top: 42%;
		left: 78%;
	}
	.PolaStatic {
		display: block;
		width: 100%;
	}
	.PolaAnimated {
		display: none;
	}
	.PolaStatic.unable {
		display: none;
	}
	.PolaAnimated.active {
		display: block;
		width: 100%;
	}
	.ArrowPolaOne {
		position: absolute;
		top: 43%;
		left: 83.4%;
		z-index: 310;
		width: calc(250px * #{$offset});
		transform: rotate(152deg);
	}
	.ClickHerePola {
		position: absolute;
		top: 41%;
		left: 86%;
		width: calc(160px * #{$offset});
		animation: Click 0.2s ease-in-out infinite;
	}

	/* ---- TEXT ---- */
	.BottleText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 28%;
		left: 33.5%;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.ElectribeText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 60%;
		left: 20%;
		z-index: 200;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}

	.PlaylistText {
		width: calc(550px * #{$offset});
		position: absolute;
		top: 12%;
		left: 11%;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.YouRockText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 77%;
		left: 78%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.MoviesText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 11.5%;
		left: 42.5%;

		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}

	.RecordingText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 9%;
		left: 24.9%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.PolaText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 42.5%;
		left: 78.7%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			text-align: center;
		}
	}
	.TheLooksText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 72%;
		left: 93%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			text-align: center;
		}
	}
	.DrawText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 7%;
		left: 69%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.DrawsText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 22%;
		left: 78%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.AndrollText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 68%;
		left: 50.5%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}
	.MadeInMusicText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 90%;
		left: 13%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}
	.OriginalText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 42%;
		left: 33%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}
	.OrganicText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 34%;
		left: 67%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: left;
		}
		p {
			width: 100%;
			text-align: left;
		}
	}
	.CreditsText {
		width: calc(500px * #{$offset});
		position: absolute;
		top: 98%;
		left: 95%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}
	.PhotoBoothText {
		width: calc(500px * #{$offset});
		position: absolute;
		bottom: 7%;
		right: 7.5%;
		z-index: 100;
		h1 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
	}
	.JulienText {
		position: absolute;
		top: 76%;
		left: 25.4%;
		z-index: 200;
		p {
			text-align: center;
		}
	}

	.ImmediaText {
		position: absolute;
		top: 8%;
		left: 44%;
		z-index: 200;
		p {
			text-align: center;
		}
	}

	.TVButton {
		position: absolute;
		bottom: 6%;
		right: 9.5%;
		color: white;
		background: black;
		border: solid 1px white;
		z-index: 100;
		text-transform: uppercase;
		font-size: calc(19.2px);
		padding: 5px 20px;
		cursor: pointer;
		outline: none;
		font-family: "Times New Roman", Times, serif;
	}
	.TakePhoto {
		position: absolute;
		bottom: 21%;
		right: 2.7%;
		color: white;
		background: black;
		border: solid 1px white;
		z-index: 100;
		text-transform: uppercase;
		font-size: calc(19.2px);
		padding: 5px 20px;
		cursor: pointer;
		outline: none;
		font-family: "Times New Roman", Times, serif;
	}
	.SavePhoto {
		position: absolute;
		bottom: 10.8%;
		right: 3.02%;
		text-decoration: none;
		color: white;
		background: black;
		border: solid 1px white;
		z-index: 100;
		text-transform: uppercase;
		font-size: calc(19.2px);
		padding: 5px 20px;
		cursor: pointer;
		outline: none;
		font-family: "Times New Roman", Times, serif;
	}

	.TV {
		position: absolute;
		bottom: 25%;
		right: 3%;
		width: calc(1400px * #{$offset});
		.TVWrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 3;
			// opacity: 0;
		}
		.WebcamFilter {
			position: absolute;
			z-index: 2;
			margin-top: 11%;
			margin-left: 16%;
			width: calc(800px * #{$offset});
			height: calc(606px * #{$offset});
		}

		video {
			position: absolute;
			z-index: 1;
			margin-top: 11%;
			margin-left: 16%;
			filter: saturate(0);
			width: calc(800px * #{$offset});
			height: calc(606px * #{$offset});
		}
	}
	.PictureCanvas {
		position: absolute;
		display: none;
	}
	.PhotoResult {
		position: absolute;
		bottom: 12%;
		right: 1.9%;
		filter: saturate(0);
		z-index: 50;
		width: calc(300px * #{$offset});
		opacity: 0;
		transition: opacity 1s ease-in-out;
		&.active {
			opacity: 1;
		}
	}
	.WebcamClickable {
		position: absolute;
		z-index: 100;
		width: 50px;
		height: 100px;
		bottom: 18.4%;
		right: 5.3%;
		cursor: pointer;
		&.two {
			bottom: 15.3%;
		}
		&.three {
			bottom: 12.3%;
		}
	}
	.ArrowWebcam {
		position: absolute;
		bottom: 4.2%;
		right: 11%;
		z-index: 310;
		width: calc(250px * 0.8);
		transform: rotate(-384deg);
	}
	.ClickHereWebcam {
		position: absolute;
		bottom: 2.8%;
		right: 13.8%;
		width: calc(160px * #{$offset});
		animation: Click 1s ease-in-out infinite;
	}
	.WebcamFilterText {
		position: absolute;
		bottom: 18.8%;
		left: 95%;
		color: white;
		font-size: calc(20px * #{$offset});
		z-index: 100;
		font-weight: bold;
		&.two {
			bottom: 16%;
		}
		&.three {
			bottom: 13%;
		}
	}
}

@include styling($offset);

@media screen and (max-width: 1600px) {
	@include styling($offset: 0.8);
}
@media screen and (max-width: 700px) {
	@include styling($offset: 0.5);
	.ClickHereElectribe,
	.ClickHereTshirt,
	.ArrowBottleOne,
	.ArrowElectribeOne,
	.ArrowTshirtOne,
	.ClickHereBottle,
	.DrawingButton,
	.TVButton,
	.DrawText,
	.ArrowWebcam,
	.ClickHereWebcam,
	.JulienText,
	.BottleText,
	.PhotoBoothText {
		display: none;
	}
}

body,
html {
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: "Times New Roman", sans-serif;
}

img,
svg,
h1,
h2,
p,
li,
h3,
h4,
ul,
iframe {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.ScrollWrapper {
	position: relative;
	width: 100v;
	height: 100vh;
	perspective: 100px;
	transform-style: preserve-3d;
}

@keyframes CdRotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes clignoting {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes Click {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes Flying {
	0% {
		transform: translate3d(0px, 0px, 70px) rotate(0deg);
	}
	50% {
		transform: translate3d(20px, 75px, 70px) rotate(20deg);
	}

	100% {
		transform: translate3d(0px, 0px, 70px) rotate(0deg);
	}
}
